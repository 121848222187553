import React, { useState } from 'react'
import ModalHome from './ModalHome'
import Modal from '../elements/Modal'
import mintIcon from '../../assets/vector/leftBarIcons/mintIcon'
import waifuHome from "../../assets/img/WaifuHome.svg"

import IconMint from "../../assets/img/IconMint.svg"
import IconInvectory from "../../assets/img/IconInventory.svg"
import IconLicencia from "../../assets/img/IconLicencia.svg"
import IconHarvesting from "../../assets/img/IconHarvesting.svg"
import IconClaim from "../../assets/img/IconClaim.svg"
import IconHospital from "../../assets/img/IconHospital.svg"


import waifugeneral from "../../assets/img/WaifuMint.png"
/* import waifuHarvesting from "../../assets/img/WaifuHarvesting.png" */
import waifuMecainica from "../../assets/img/WaifuMecanica.svg"
/* import waifuLicecia from "../../assets/img/WaifuLicencia.png" */




export default function Home() {


    const [modal, setModal] = useState(false)


    const informacion = {

        mint: { title: "MINT ", label: "Lorem ipsum dolor sit amet", imagen: waifugeneral, key: "mint", icon: IconMint, description: "Lorem ipsum dolor sit amet consectetuer adipiscing.Luismod tincidunt ut laoreet dolore magna aliquam. Ut wisi enim ad minim veniam, quis nostrud. Duis autem vel eum iriure dolor in hendrerit. Praesent luptatum zzril delenit augue duis.Ut wisi enim ad minim veniam, quis nostrud." },
        inventory: { title: "INVENTORY", label: "Lorem ipsum dolor sit amet", imagen: waifugeneral, key: "inventory", icon: IconInvectory, description: "Lorem ipsum dolor sit amet consectetuer adipiscing.Luismod tincidunt ut laoreet dolore magna aliquam. Ut wisi enim ad minim veniam, quis nostrud. Duis autem vel eum iriure dolor in hendrerit. Praesent luptatum zzril delenit augue duis.Ut wisi enim ad minim veniam, quis nostrud." },
        licencia: {
            title: "LICENSE", label: "Lorem ipsum dolor sit amet", imagen: waifugeneral, key: "licencia", icon: IconLicencia, description: "Lorem ipsum dolor sit amet consectetuer adipiscing.Luismod tincidunt ut laoreet dolore magna aliquam. Ut wisi enim ad minim veniam, quis nostrud. Duis autem vel eum iriure dolor in hendrerit. Praesent luptatum zzril delenit augue duis.Ut wisi enim ad minim veniam, quis nostrud."
        },
        harvesting: {
            title: "HARVEST", label: "Lorem ipsum dolor sit amet", imagen: waifugeneral, key: "harvesting", icon: IconHarvesting, description: "Lorem ipsum dolor sit amet consectetuer adipiscing.Luismod tincidunt ut laoreet dolore magna aliquam. Ut wisi enim ad minim veniam, quis nostrud. Duis autem vel eum iriure dolor in hendrerit. Praesent luptatum zzril delenit augue duis.Ut wisi enim ad minim veniam, quis nostrud."
        },
        claim: {
            title: "CLAIM", label: "Lorem ipsum dolor sit amet", imagen: waifugeneral, key: "claim", icon: IconClaim, description: "Lorem ipsum dolor sit amet consectetuer adipiscing.Luismod tincidunt ut laoreet dolore magna aliquam. Ut wisi enim ad minim veniam, quis nostrud. Duis autem vel eum iriure dolor in hendrerit. Praesent luptatum zzril delenit augue duis.Ut wisi enim ad minim veniam, quis nostrud."
        },
        hospital: {
            title: "HOSPITAL / MECHANIC", label: "Lorem ipsum dolor sit amet", imagen: waifuMecainica, key: "hospital", icon: IconHospital, description: "Lorem ipsum dolor sit amet consectetuer adipiscing.Luismod tincidunt ut laoreet dolore magna aliquam. Ut wisi enim ad minim veniam, quis nostrud. Duis autem vel eum iriure dolor in hendrerit. Praesent luptatum zzril delenit augue duis.Ut wisi enim ad minim veniam, quis nostrud."
        },

    }



    return (<>
        <h3 className='text-lg font-bold mt-4 text-center text-[#AA2E74]'></h3>
        {/*  <div className='flex items-center h-[80vh] justify-center '>  <Modal modal={modal} setModal={setModal}>  <ModalHome {...informacion[modal]} />  </Modal>

            <div className=' grid grid-cols-2 gap-2'> {Object.keys(informacion).map(element =>

                <div className='bg-[#AA2E74] cursor-pointer flex  items-center p-2 ml-4 mt-5  rounded-md' onClick={() => { setModal(informacion[element].key) }}>
                    <div className=''>
                        <h4 className='text-[#fff] font-bold text-md'>{informacion[element].title}</h4>
                        <p className='text-md text-[#fff]'>  {informacion[element].label}</p>
                    </div>

                    <img className='h-[2rem] ml-2' src={informacion[element].icon} alt="" />
                </div>)

            }

            </div>

            <div ><img src={waifuHome} alt="waifuhome" className='h-[20rem] ml-3' /></div>
        </div> */}
    </>
    )
}
